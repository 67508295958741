import React from 'react';
import HeroSection from './components/HeroSection';
import BookList from './components/BookList';
import ContactSection from './components/ContactSection';
import { useSelector } from 'react-redux';

const BookSection = () => {
    const booksPage = useSelector(state => state.booksPage)
    console.log('booksPage state: ', booksPage)

    return (
        <>
            {!booksPage && <HeroSection />}
            <BookList />
            <ContactSection />
        </>
    )
}

export default BookSection
