import React from 'react';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
    container: {
        border: '1px solid gray',
        borderRadius: '10px',
        padding: '30px',
        marginTop: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    textBox: {
        height: '70px'
    },
    title: {
        fontSize: '30px',
        textAlign: 'center'
    },
    button: {
        marginTop: '20px',
        marginTop: '20px'
    },
    textMessage: {
        height: '150px'
    }
}))

const ContactSection = () => {
    const classes = useStyles();
    return (
        <form target="_blank" action="https://formsubmit.co/natnaelasmare777@gmail.com" method="POST">
            <Container maxWidth="sm" id="contacts">
            <div className={classes.container}>
                <Typography 
                    variant="h5" 
                    component="string"
                    display="block" 
                    gutterBottom
                    className={classes.title}
                    gutterBottom
                    >
                    Contact us
                </Typography>
                <TextField 
                    id="outlined-basic" 
                    label="Your name here" 
                    variant="outlined" 
                    name="name"
                    required
                    // fullWidth
                    className={classes.textBox}
                    />
                <TextField 
                    id="outlined-basic" 
                    label="Your email" 
                    variant="outlined" 
                    type="email"
                    name="email"
                    required
                    // fullWidth
                    className={classes.textBox}
                    />
                <TextField
                    id="outlined-multiline-static"
                    label="Message"
                    multiline
                    rows={4}
                    placeholder="Your message here"
                    name="message"
                    required
                    // fullWidth
                    className={classes.textMessage}
                    />
                <Button 
                    variant="contained"
                    className={classes.button}
                    type="submit"
                    >Submit</Button>
            </div>
            </Container>
        </form>
    )
}

export default ContactSection
