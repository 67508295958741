import { createSlice } from "@reduxjs/toolkit";

const initialState = '';

const bookIDSlice = createSlice({
    name: 'bookID',
    initialState,
    reducers: {
        setBookID (state, action){
            return action.payload
        }
    }
});

export const { setBookID } =  bookIDSlice.actions;
export default bookIDSlice.reducer;