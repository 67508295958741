import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { makeStyles } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button'
import App from '../../App'
import { Link } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
    border: {
        border: '1px solid gray',
        borderRadius: '10px',
        padding: '25px',
        marginTop: '25px'
    },
    titleSection: {
        paddingLeft: '65px',
        marginTop: '30px'
    }
}))

const SummaryPage = () => {
    const [dense, setDense] = React.useState(false);
    const [book, setBook] = useState('');
    const bookID = useSelector(state => state.bookID)
    const [points, setPoints] = useState('')
    // let points = '';
    const history = useHistory();
    // http://test.mebrekindustrialengineering.com/api/
    useEffect(()=>{
        fetch(`https://test.mebrekindustrialengineering.com/api/getbook/${bookID}`)
            .then(response => response.json())
            .then(book => {setBook(book[0]); setPoints(book[0].points)})
    },[])

    const classes = useStyles();
    const pointsArray = [];
    let point = '';
    let previousChar = '';

    // let points = `* Marconi's "friends" had him taken into custody, and examined in a psychopathic hospital, when he announced he had discovered a principle through which he could send messages through the air, without the aid of wires, or other direct physical means of communication.
    //               * No one ever is defeated until defeat has been accepted as a reality.
    //               * Repetition of affirmation of orders to your subconscious mind is the only known method of voluntary development of the emotion of faith.`;
    
    for(let char of points) {
        if(char === '-' && previousChar === '\n' ||
           char === '•' && previousChar === '\n' || 
           char === '-' && previousChar === ''){
            if(point.length > 0){ pointsArray.push(point) }
            point = ''
            continue
        }
        if(!isNaN(char) && previousChar === '@'){
            if(point.length > 0){ pointsArray.push(point.slice(0,point.length-1)) }
            point = ''
        }
        if(char === '*' && previousChar === '*'){
            if(point.length > 0){ pointsArray.push(point.slice(0,point.length-1)) }
            point = ''
            continue
        }
        if(point.length === 1){
            point += char.toUpperCase()
        }else{
            point += char
        };
        previousChar = char;
    }
    if(point.length > 0){ pointsArray.push(point) }

    // console.log(pointsArray)
    if(bookID){
    return (
        <div>
            <Container maxWidth="md" className={classes.titleSection}>
                <Typography 
                    variant="h4" 
                    gutterBottom 
                    component="h1"
                    gutterBottom="true"
                    >
                    {book.name}
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="h2">
                    By {book.autor}
                </Typography>
            </Container>
            <Container maxWidth="md" className={classes.border}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="h2">
                    Summary points
                </Typography>
                
                <List dense={dense}>
                    {pointsArray.map(point=>(
                         <ListItem>
                            <ListItemIcon>
                                <AcUnitIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={point.trim()}
                            /> 
                         </ListItem>
                    ))}
                </List>
            </Container>
        </div>
    )}else{
        
        return(<p style={{textAlign: 'center'}}>Content not found.</p>)
    }
}

export default SummaryPage
