import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Link } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MessageIcon from '@mui/icons-material/Message';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    avatar: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        // border: '1px solid gray',
        // marginLeft: '125px'
    },
    avatarContainer: {
        width: '170px',
        // border: '1px solid gray',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    navWrapper: {
        width: '1050px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // border: '1px solid red',
        [theme.breakpoints.down('md')]: {
            width: '95%',
          },
    },
    navBox: {
        [theme.breakpoints.down('md')]: {
            width: '90%',
          },
        // border: '1px solid gray'
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
          },
    },
    mobileNav: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
          },
    },
    buttonGroup: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        // border: '1px solid gray'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F8FAFC',
        // border: '1px solid gray',
    },
    title: {
        fontSize: '1.3rem',
        color: '#222',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.1rem',
          },
        // border: '1px solid gray',
        width: '170px'
    },
    linkedButton: {
        backgroundColor: "#3897D3",
        color: 'red'
    },
    border: {
        // border: '1px solid gray',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px'
    }

    // border: {
    //     border: '#222',
    // },
  }));

const Nav = () => {
    const classes = useStyles();
    const [mobNav, setMobNav] = useState('false');

    const onNavMenuClick = () => {
        setMobNav(!mobNav)
    }

    return (
        <AppBar 
            position="static" 
            className={classes.container}
            elevation={0}
            >
            <Box className={classes.navBox}>
            <Toolbar 
                className={classes.navWrapper}
                px={2}
                >
                <Typography 
                    variant="overline" 
                    className={classes.title}
                    >
                    Wisdom Steal
                </Typography>
                <ButtonGroup 
                    variant="contained" 
                    color="primary" 
                    aria-label="contained primary button group"
                    className={classes.buttonGroup}
                    >
                    <Button
                        component={Link} to={'/'} id="homeButton"
                        >Home</Button>
                        <Button 
                            component={LinkS} 
                            to={'books'}
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            id="booksButton"
                            onClick={()=>{ //this section of the code is responsible for changing the route and then scrolling to the desired section
                                let thePath = window.location.href;
                                let lastIndex = thePath.substring(thePath.lastIndexOf('/') + 1)
                                if(lastIndex === 'summary'){
                                    document.querySelector("#homeButton").click()
                                    setTimeout(function() {
                                        document.querySelector("#booksButton").click()
                                    }, 200);
                                }
                            }}
                            >Books</Button>
                    <Button disabled>Tech</Button>
                    <Button
                            component={LinkS} 
                            to={'contacts'}
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            id="contactsButton"
                            onClick={()=>{ //this section of the code is responsible for changing the route and then scrolling to the desired section
                                let thePath = window.location.href;
                                let lastIndex = thePath.substring(thePath.lastIndexOf('/') + 1)
                                if(lastIndex === 'summary'){
                                    document.querySelector("#homeButton").click()
                                    setTimeout(function() {
                                        document.querySelector("#contactsButton").click()
                                    }, 200);
                                }
                            }}
                            >Contact</Button>
                    {/* <Button>About</Button> */}
                </ButtonGroup>
                <Box className={classes.avatarContainer}>
                    {/* <Avatar className={classes.avatar}>X</Avatar>    */}
                </Box>
                <IconButton 
                    edge="start" 
                    className={classes.menuButton} 
                    color="primary" 
                    aria-label="menu"
                    onClick={onNavMenuClick}
                    id="menuButton"
                    >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
            </Box>
            {!mobNav && <List sx={{ width: '100%' }} className={classes.mobileNav}>
            <div className={classes.border}>
                <Button 
                    variant="outlined" 
                    onClick={()=>{
                        document.querySelector("#homeButton").click()
                        document.querySelector("#menuButton").click()
                        }}
                    >Home</Button>
            </div>
            <div className={classes.border}>
                <Button 
                    variant="outlined" 
                    onClick={()=>{
                        document.querySelector("#booksButton").click()          
                        document.querySelector("#menuButton").click()
                    }}
                    >Books</Button>
            </div>
            <div className={classes.border}>
                <Button 
                    variant="outlined" 
                    onClick={()=>{
                        document.querySelector("#contactsButton").click()
                        document.querySelector("#menuButton").click()
                    }}
                    >Contact</Button>
            </div>
            </List>}
        </AppBar>
    )
}

export default Nav
