import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBookID } from '../../BookIDSlice';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme)=>({
    container: {
        // border: '1px solid gray',
        marginTop: '40px'
    },
    root: {
        maxWidth: 245,
        // [theme.breakpoints.down('sm')]: {
        //     maxWidth: 200,
        // },
      },
      media: {
        width: 245,
        height: 350,
        padding: '0 auto',
        // [theme.breakpoints.down('sm')]: {
        //     width: 200,
        //     height: 300,
        // },
      },
      gridContainer: {
        //   border: '1px solid gray'
      },
      gridItem: {
          display: 'flex',
          justifyContent: 'center'
      },
      searchContainer: {
        // border: '1px solid gray',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '50px'
    },
    searchField: {
        width: '300px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
        },
        // height: '30px'
    },
    linking: {
        textDecoration: 'none'
    }
}))

const BookList = () => {
    const classes = useStyles();
    const [bookList, setBookList] = useState([])
    const [searchField, setSearchField] = useState('')

    let dispatch = useDispatch()
    let summaryPage = useSelector(state => state.summaryPage)
    let bookID = useSelector(state => state.bookID)
    // https://mebrekindustrialengineering.com:2083/cpsess1264804282/viewer/home2%2fmebrekindustrial%2fpublic_ftp%2fimg/atomic%20habits.jpg
    useEffect(()=>{
        fetch('https://test.mebrekindustrialengineering.com/api/getbooks')
            .then(response => response.json())
            .then(books => {setBookList(books)})
            .catch(error => console.log('fetch error: ', error))
    },[])
    
    return (
        <Container id="books"
            maxWidth="md"
            className={classes.container}
            >
            <Container maxWidth="md" className={classes.searchContainer}>
                <Autocomplete 
                    id="free-solo-demo"
                    freeSolo
                    // options={top100Films.map((option) => option.title)}
                    options={bookList.map(book => book.name)}
                    renderInput={(params) => (
                    <TextField 
                        className={classes.searchField}
                        {...params} 
                        label="Search books" 
                        margin="normal" 
                        variant="outlined" 
                        size="small"
                        value={searchField}
                        onChange={(e)=>{setSearchField(e.target.value)}}
                        /> 
                    )}
                />
            </Container>
            <Grid container spacing={3} className={classes.gridContainer}>
                {bookList.filter(book => book.name.toLowerCase().includes(searchField.toLowerCase())||
                                         book.autor.toLowerCase().includes(searchField.toLowerCase())).map(book=>(                 
                   <Grid 
                        item 
                        md={4} 
                        sm={6} 
                        xs={12}
                        className={classes.gridItem}
                        key={book.id}
                        >
                        <Card className={classes.root} elivation={3}>
                        <CardActionArea 
                                component={Link} 
                                to={'/summary'}
                                onClick={()=>{
                                    dispatch(setBookID(book.id));
                                    }}
                                >
                            <CardMedia
                            className={classes.media}
                            image = {`./img/${book.image}`}
                            title={book.name}
                            />
                            <CardContent>
                            <Typography gutterBottom variant="overline">
                                {book.category}
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2">
                                {book.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                by {book.autor}
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            
                            <Button 
                                size="small" 
                                color="primary"
                                component={Link} 
                                to={'/summary'}
                                onClick={()=>{
                                    dispatch(setBookID(book.id));
                                    }}
                                >
                                    View Summary
                            </Button>
                        </CardActions>
                        </Card>
                    </Grid> 
                )
                )}
            </Grid>
            
        </Container>
    )
}

export default BookList
