import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import bookReadingImage from './imgs/bookReading.svg';

const useStyles = makeStyles((theme) => ({
    container: {
        border: '1px solid gray',
        borderRadius: '25px',
        margin: '30px auto 30px auto',
        // marginBottom: '30px'
        [theme.breakpoints.down('xs')]: {
            border: 'none'
          },
    },
    paper: {
        textAlign: 'center',
        height: '150px',
        width: '100%',
        backgroundColor: '#eee'
    },
    gridContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    overline: {
        fontSize: '1.3rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
          },
    },
    headers: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '40px',
          },
    },
    subtitle: {
        fontSize: '1.3rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
          },
    },
    imageSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: 400,
        // border: '1px solid red',
        // backgroundImage: `url(${bookReadingImage})`,
        // backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat',
        padding: '45px',
        [theme.breakpoints.down('sm')]: {
            padding: '25px',
          },
    },
    img: {
        width: '100%'
    },
    headerSection: {
        padding: 'auto 0',
        // border: '1px solid gray'
    },
}))

const HeroSection = () => {
    const classes = useStyles();

    return (
        <Container 
            className={classes.container}
            maxWidth="lg"
            >
            <Grid 
                container 
                spacing={2} 
                className={classes.gridContainer}
                my={3}
                >
                <Grid item xs={12} sm={6}>
                    <Box className={classes.headerSection}>
                        <Typography 
                            variant="overline"
                            className={classes.overline}
                            color="primary"
                            >
                            Wisdom from the experts
                        </Typography>
                        <Typography 
                            variant="h2" 
                            component="h1"
                            className={classes.headers}
                            >
                            Book summaries to the point, literally
                        </Typography>
                        <Typography 
                            variant="subtitle1"
                            className={classes.subtitle}
                            color="secondary"
                            >
                            Ideas that resonate.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box 
                        className={classes.imageSection}
                        >
                        <img src={bookReadingImage} className={classes.img}/>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default HeroSection
