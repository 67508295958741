import React from 'react';
import './App.css';
import Nav from './features/Nav';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import BookSection from './features/BookSection';
import Footer from './features/Footer';
import SummaryPage from './features/SummaryPage';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3897D3',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100,
      xl: 1200
    }
  }
});


function App() {
  let summaryPage = useSelector(state => state.summaryPage)

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Nav />
        <Switch>
          <Route path="/" exact component={BookSection} />
					<Route path="/summary" component={SummaryPage} />
        </Switch>
      </Router>
      {/* <BookSection />
      <SummaryPage /> */}
      <Footer />
    </ThemeProvider>
  );
}

export default App;
