import React from 'react';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme)=>({
    container: {
        // border: '1px solid gray',
        padding: '30px'
    },
    footer: {
        // color: "#ff0000"
    }
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="md" className={classes.container}>
            <Typography 
                my={20}
                variant="body2"
                className={classes.footer}
                align="center"
                >
                © Wisdom steal {new Date().getFullYear()}. All right reserved.
            </Typography>
        </Container>
    )
}

export default Footer
